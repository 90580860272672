var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header py-3"},[_c('div',{staticClass:"card-title align-items-start flex-column"},[_c('h3',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(_vm._s(_vm.$t('project.changePassword')))]),_c('span',{staticClass:"text-muted font-weight-bold font-size-sm mt-1"},[_vm._v(" "+_vm._s(_vm.$t('project.canChangePassword'))+" ")])]),_c('div',{staticClass:"card-toolbar"},[_c('b-button',{attrs:{"variant":"success","disabled":_vm.submitting},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.$t('project.saveChanges'))+" "),(_vm.submitting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)]),_c('form-wrapper',{staticClass:"form",attrs:{"validator":_vm.$v.form}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label text-right"},[_vm._v(" "+_vm._s(_vm.$t('project.currentPassword'))+" ")]),_c('form-group',{attrs:{"name":"oldPassword","lg":"9","xl":"6","no-label":"","no-margin":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({staticClass:"form-control-lg form-control-solid",attrs:{"type":"password"},model:{value:(_vm.form.oldPassword),callback:function ($$v) {_vm.$set(_vm.form, "oldPassword", $$v)},expression:"form.oldPassword"}},'b-input',attrs,false),listeners))}}])})],1),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label text-right"},[_vm._v(" "+_vm._s(_vm.$t('project.newPassword'))+" ")]),_c('form-group',{attrs:{"name":"newPassword","lg":"9","xl":"6","no-label":"","no-margin":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({staticClass:"form-control-lg form-control-solid",attrs:{"type":"password"},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}},'b-input',attrs,false),listeners))}}])})],1),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label text-right"},[_vm._v(" "+_vm._s(_vm.$t('project.reNewPassword'))+" ")]),_c('form-group',{attrs:{"name":"reNewPassword","lg":"9","xl":"6","no-label":"","no-margin":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({staticClass:"form-control-lg form-control-solid",attrs:{"type":"password"},model:{value:(_vm.form.reNewPassword),callback:function ($$v) {_vm.$set(_vm.form, "reNewPassword", $$v)},expression:"form.reNewPassword"}},'b-input',attrs,false),listeners))}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }